import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <section id="about" className="py-24 bg-gradient-to-b from-indigo-900 to-black relative overflow-hidden">
      <div className="absolute inset-0 grid-pattern opacity-10" />
      
      <div className="container mx-auto px-4 relative z-10">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
            About Us
          </h2>
        </motion.div>

        <div className="flex flex-col lg:flex-row items-center lg:items-start gap-12">
          <motion.div 
            className="lg:w-1/3"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-purple-600 rounded-2xl blur opacity-30 group-hover:opacity-60 transition duration-1000"></div>
              <div className="relative">
                <img 
                  src="/christian.jpg" 
                  alt="Christian Adib" 
                  className="rounded-xl shadow-2xl w-full"
                  width="400"
                  height="500"
                />
              </div>
            </div>
          </motion.div>

          <motion.div 
            className="lg:w-2/3"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <div className="bg-white/5 p-8 rounded-xl backdrop-blur-md border border-white/10">
              <h3 className="text-3xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
                Christian Adib
              </h3>
              <p className="text-xl mb-6 text-blue-300">Founder and Principal Consultant</p>
              <div className="space-y-4 text-gray-300 font-light leading-relaxed">
                <p>
                  With over a decade of experience bridging technology and business strategy, Christian has guided numerous Fortune 500 executives through successful GenAI transformations. His unique blend of technical expertise and executive communication skills enables the translation of complex AI concepts into clear, actionable strategies that drive measurable business outcomes.
                </p>
                <p>
                  Christian's approach combines deep industry knowledge with a practical understanding of GenAI's potential, ensuring that your organization not only adopts cutting-edge technology but does so in a way that creates sustainable competitive advantage and tangible ROI.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;