import React from 'react';
import { motion } from 'framer-motion';
import { Lightbulb, Users, Code, DollarSign } from 'lucide-react';

const ServiceCard = ({ title, description, icon: Icon }) => (
  <motion.div 
    className="relative group h-full" // Added h-full
    whileHover={{ y: -5 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-600/20 rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300 opacity-0 group-hover:opacity-100" />
    <div className="relative bg-white/5 p-8 rounded-xl backdrop-blur-md border border-white/10 hover:border-blue-500/50 transition-colors duration-300 h-full flex flex-col"> {/* Added h-full and flex flex-col */}
      <div className="mb-4 inline-block bg-gradient-to-r from-blue-500 to-purple-600 p-3 rounded-lg">
        <Icon className="w-6 h-6 text-white" />
      </div>
      <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
        {title}
      </h3>
      <p className="text-gray-300 font-light leading-relaxed flex-grow"> {/* Added flex-grow */}
        {description}
      </p>
    </div>
  </motion.div>
);

const Services = () => {
  const services = [
    {
      icon: Lightbulb,
      title: "Strategic Consulting",
      description: "On-demand guidance to enhance your team's AI capabilities, streamline processes, and drive growth. Includes access to expert networks and hiring assistance."
    },
    {
      icon: Users,
      title: "Comprehensive Consulting",
      description: "Hands-on collaboration with your team, including prototyping, production support, model optimization, and custom tool development. Ideal for teams looking to accelerate their AI development process."
    },
    {
      icon: Code,
      title: "Custom Development",
      description: "Tailored AI solutions and infrastructure development to meet your specific needs and challenges."
    },
    {
      icon: DollarSign,
      title: "Project-Based Consulting",
      description: "Comprehensive AI strategy and implementation over a 3-month minimum period, perfect for organizations looking for transformative results."
    }
  ];

  return (
    <section id="services" className="py-24 bg-gradient-to-b from-indigo-900 to-black">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
            Our Services
          </h2>
          <p className="text-xl text-blue-300 max-w-2xl mx-auto font-light">
            Comprehensive GenAI solutions tailored to your business needs
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 h-full"> {/* Added h-full */}
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="h-full" // Added h-full
            >
              <ServiceCard {...service} />
            </motion.div>
          ))}
        </div>

        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-center text-blue-300 mt-16 font-light"
        >
          Startup-friendly pricing available. Contact us to discuss your unique needs and explore how we can tailor our services to your budget and goals.
        </motion.p>
      </div>
    </section>
  );
};

export default Services;