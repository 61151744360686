import React from 'react';
import { motion } from 'framer-motion';
import { Search, Map, Presentation, Rocket, LineChart } from 'lucide-react';

const ApproachStep = ({ icon: Icon, title, description, index }) => (
  <motion.div 
    className="relative group h-full"
    whileHover={{ y: -5 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-600/20 rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300 opacity-0 group-hover:opacity-100" />
    <div className="relative bg-white/5 p-8 rounded-xl backdrop-blur-md border border-white/10 hover:border-blue-500/50 transition-colors duration-300 h-full flex flex-col">
      <div className="flex items-center mb-6">
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-3 rounded-lg mr-4">
          <Icon className="w-6 h-6 text-white" />
        </div>
        <h3 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
          {title}
        </h3>
      </div>
      <p className="text-gray-300 font-light leading-relaxed flex-grow">
        {description}
      </p>
      <div className="mt-4 text-3xl font-bold text-blue-500/20">
        {(index + 1).toString().padStart(2, '0')}
      </div>
    </div>
  </motion.div>
);

const Approach = () => {
  const steps = [
    {
      icon: Search,
      title: 'Executive Discovery',
      description: 'In-depth executive interviews and workshops to understand your unique challenges, objectives, and existing AI capabilities.',
    },
    {
      icon: Map,
      title: 'Strategic Roadmapping',
      description: 'Developing a comprehensive GenAI roadmap aligned with your business goals and technical requirements.',
    },
    {
      icon: Presentation,
      title: 'Stakeholder Alignment',
      description: 'Facilitating cross-functional alignment and building consensus around the implementation strategy.',
    },
    {
      icon: Rocket,
      title: 'Phased Implementation',
      description: 'Executing the strategy in manageable phases, ensuring quick wins while building toward long-term success.',
    },
    {
      icon: LineChart,
      title: 'Impact Measurement',
      description: 'Implementing robust metrics and reporting to track ROI and demonstrate value creation.',
    },
  ];

  return (
    <section id="approach" className="py-24 bg-gradient-to-b from-black to-indigo-900">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
            Our Executive-Focused Approach
          </h2>
          <p className="text-xl text-blue-300 max-w-2xl mx-auto font-light">
            A proven methodology for successful GenAI implementation
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={step.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="h-full"
            >
              <ApproachStep {...step} index={index} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Approach;