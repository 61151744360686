import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, X } from 'lucide-react';

const CaseStudyCard = ({ icon: Icon, title, industry }) => (
  <motion.div 
    className="relative group cursor-pointer"
    whileHover={{ y: -5 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-600/20 rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300 opacity-0 group-hover:opacity-100" />
    <div className="relative bg-white/5 p-6 rounded-xl backdrop-blur-md border border-white/10 hover:border-blue-500/50 transition-colors duration-300">
      <div className="flex items-center mb-4">
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-2 rounded-lg mr-4">
          <Icon className="w-6 h-6 text-white" />
        </div>
        <div>
          <h4 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
            {title}
          </h4>
          <p className="text-sm text-blue-300 mt-1">{industry}</p>
        </div>
      </div>
      <div className="flex items-center text-blue-300 group-hover:text-blue-400 transition-colors">
        <span className="mr-2">Learn more</span>
        <ChevronRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
      </div>
    </div>
  </motion.div>
);

const CaseStudyModal = ({ study, onClose }) => (
  <motion.div 
    className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <motion.div 
      className="bg-gradient-to-br from-indigo-900 to-black rounded-xl max-w-2xl w-full relative border border-blue-500/30"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.9, opacity: 0 }}
    >
      <div className="p-6 border-b border-blue-500/30">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-blue-300 hover:text-white transition-colors"
          aria-label="Close modal"
        >
          <X size={24} />
        </button>
        <div className="flex items-center">
          <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-3 rounded-lg mr-4">
            <study.icon className="w-8 h-8 text-white" />
          </div>
          <div>
            <h3 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
              {study.title}
            </h3>
            <p className="text-blue-300 mt-1">{study.industry}</p>
          </div>
        </div>
      </div>
      
      <div className="p-6 space-y-6">
        {['Challenge', 'Solution', 'Outcome'].map((section, index) => (
          <div key={section}>
            <h4 className="text-lg font-bold text-blue-300 mb-2">{section}</h4>
            <p className="text-gray-300 font-light leading-relaxed">
              {index === 0 ? study.challenge : index === 1 ? study.solution : study.outcome}
            </p>
          </div>
        ))}
      </div>
      
      <div className="p-6 border-t border-blue-500/30">
        <button 
          onClick={onClose}
          className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-4 rounded-lg hover:from-blue-600 hover:to-purple-700 transition-all duration-300"
        >
          Close
        </button>
      </div>
    </motion.div>
  </motion.div>
);

const CaseStudies = ({ studies }) => {
  const [selectedStudy, setSelectedStudy] = useState(null);

  return (
    <section id="case-studies" className="py-24 bg-gradient-to-b from-black to-indigo-900">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
            Success Stories
          </h2>
          <p className="text-xl text-blue-300 max-w-2xl mx-auto font-light">
            Real results from our enterprise GenAI implementations
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {studies.map((study, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              onClick={() => setSelectedStudy(study)}
            >
              <CaseStudyCard {...study} />
            </motion.div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {selectedStudy && (
          <CaseStudyModal 
            study={selectedStudy} 
            onClose={() => setSelectedStudy(null)} 
          />
        )}
      </AnimatePresence>
    </section>
  );
};

export default CaseStudies;